.person-activity-wrapper {
  min-height: calc(100vh - 200px);
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 16px;
}

.person-activity-wrapper.status-update {
  padding-top: 0px;
}

.person-activity-wrapper .activity-wrapper--header {
  text-align: center;
  color: #3d3d46;
  width: 100%;
}
.person-activity-wrapper #next-person-btn {
  position: absolute;
  left: 12px;
  top: 76px;
  z-index: 100;
}
.person-activity-wrapper #done-btn {
  position: absolute;
  top: 320px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}
.person-activity-wrapper #start-btn {
  position: absolute;
  left: auto;
  right: auto;
  top: 50%;
}
.person-activity-wrapper .oval-table::before {
  content: "";
  position: absolute;
  top: 178px;
  right: 50px;
  bottom: 178px;
  left: 50px;
  border: 4px dotted rgba(61, 61, 70, 0.3);
  border-radius: 50%;
}
