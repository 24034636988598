.transparent-container {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 95.85%);
}

@keyframes plusRotateRight {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

@keyframes plusRotateLeft {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}

.rotate-plus-right {
  animation: plusRotateRight 200ms ease-in-out;
}

.rotate-plus-left {
  animation: plusRotateLeft 200ms ease-in-out;
}

@keyframes crossRotateRight {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

@keyframes crossRotateLeft {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}

.rotate-cross-right {
  animation: crossRotateRight 200ms ease-in-out;
}

.rotate-cross-left {
  animation: crossRotateLeft 200ms ease-in-out;
}

.episode-options {
  transition: max-height 400ms ease-in-out;
}

.height-animation {
  max-height: 600px;
}

@keyframes fadeIn {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

.fade-in {
  animation: fadeIn 400ms ease-in-out;
}

@keyframes fadeOut {
  0% {
    opacity: 100%;
  }

  100% {
    opacity: 0%;
  }
}

.fade-out {
  animation: fadeOut 400ms ease-in-out;
}
