.masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: 40px; /* gutter size offset */
  width: 744px;
}
.masonry-grid_column {
  padding-left: 40px; /* gutter size */
  background-clip: padding-box;
}
