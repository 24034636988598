.meeting-control {
  width: 100%;
  height: 69px;
  position: fixed;
  z-index: 101;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  border-top: 1px solid #e0e0e0;
  background-color: #fff;
}
.meeting-control--wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  opacity: 1;
}
.meeting-control--wrapper.hide {
  opacity: 0;
}
.meeting-control--animate {
  transition: transform 0.2s linear;
  transform: translate3d(0px, 80px, 0px);
  position: fixed;
  right: 0;
  bottom: 0;
  opacity: 0;
}
.meeting-control--animate.show {
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
}
